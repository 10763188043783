import React, { useState, useRef } from 'react';
import { TextField, Dialog, DialogActions, DialogContent, 
  DialogTitle, Box, IconButton, Grid, CircularProgress } from '@mui/material';
import { postFile } from '../../utils/api';
import { formatToLocalPhoneNumber, isValidPhoneNumber } from "../../utils/helper_functions";
import { isValidEmail } from "../../utils/regex";
import { GoogleInput } from '../reusable/inputs/google_input';
import { CloseButton, PrimaryButton } from '../reusable/Buttons';
import { CustomAvatar } from "../Layout";
import EditIcon from '@mui/icons-material/Edit';

const OrganisationForm = ({ open, setOpen, newOrg, setNewOrg, handleCreateOrUpdate, onClearForm }) => {
  const [formError, setFormError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const handlePlaceInput = (e) => {
    setNewOrg(prevState => ({
      ...prevState, address: {
        address: e.target.value,
        lat: '',
        lng: ''
      }
    }));
  };

  const onPlaceSelected = (place) => {
    if (!place?.geometry) {
      setNewOrg(prevState => ({
        ...prevState, address: {
          address: '',
          lat: 0,
          lng: 0,
        }
      }));
      return
    }
    const location = place.geometry.location
    const addressComponents = place.address_components
    const state = addressComponents.find((component) => component.types.includes('administrative_area_level_1'))?.short_name
    const suburb = addressComponents.find((component) => component.types.includes('locality'))?.long_name
    const postcode = addressComponents.find((component) => component.types.includes('postal_code'))?.long_name
    const country = addressComponents.find((component) => component.types.includes('country'))?.long_name
    setNewOrg(prevState => ({
      ...prevState, address: {
        address: place.formatted_address,
        lat: location.lat(),
        lng: location.lng(),
        state,
        suburb,
        postcode,
        country
      }
    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOrg(prevState => ({ ...prevState, [name]: value }));
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const changeEmail = (event) => {
    setNewOrg((prevData) => ({
      ...prevData,
      email: event.target.value,
    })
    );
    setEmailError(!isValidEmail(event.target.value));
  };

  const changePhone = (event) => {
    const phone = event.target.value;
    const isValidPhone = isValidPhoneNumber(phone)

    setNewOrg((prevData) =>
    ({
      ...prevData,
      phone_number: formatToLocalPhoneNumber(event.target.value),
    })
    );
    setPhoneError(!isValidPhone);
  };


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        const response = await postFile('/upload', file);
        if (response.url) {
          setNewOrg({ ...newOrg, logo_url: response.url });
        }
      } catch (error) {
        setFormError('Failed to upload logo.');
        return;
      } finally {
        setIsUploading(false);
      }
    }
  };

  function validateFormCreate() {
    return (
      newOrg.name !== "" &&
      newOrg.email !== "" &&
      newOrg.phone_number !== "" &&
      newOrg.abn !== "" &&
      newOrg.address?.lat &&
      !emailError &&
      !phoneError
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newOrg.name  || !newOrg.email || !newOrg.abn || !newOrg.phone_number) {
      setFormError('All fields are required.');
      return;
    }
    handleCreateOrUpdate()
      .then(() => {
        setOpen(false);
        setFormError(null);
      })
      .catch((error) => {
        setFormError(error.message || 'An error occurred.');
      });
  };

  return (
    <Dialog sx={{ zIndex: 999 }} open={open} onClose={() => { setOpen(false); }} fullWidth maxWidth="sm">
      <DialogTitle>Create Organisation</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box position="relative" display="inline-block">
                <CustomAvatar
                  src={newOrg.logo_url}
                  alt="Avatar"
                  sx={{ width: 80, height: 80, mt: 2 }}
                />
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: '15px',
                    right: '5px',
                    backgroundColor: "#8a2be2",
                    color: "white",
                    width: 24,
                    height: 24,
                    "&:hover": {
                      backgroundColor: "#6a1bb2",
                    },
                    zIndex: 999,
                  }}
                  onClick={handleEditClick}
                >
                  <EditIcon sx={{ fontSize: 16 }} />
                </IconButton>
                {isUploading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={4} sm={9}>
              <TextField label="Organisation name" name="name" value={newOrg.name || ''} fullWidth onChange={(e) => setNewOrg({ ...newOrg, name: e.target.value })} />
            </Grid>
          </Grid>
          <TextField label="Description" name="description" value={newOrg.description || ''} fullWidth onChange={handleInputChange} />
          <TextField label="ABN" name="abn" value={newOrg.abn || ''} fullWidth onChange={handleInputChange} />
          <TextField label="Email" name="email" value={newOrg.email || ''} fullWidth onChange={changeEmail} color={isValidEmail(newOrg.email) ? "success" : "error"} />
          <TextField label="Phone Number" name="phone_number" value={newOrg.phone_number || ''} fullWidth onChange={changePhone} type="tel" color={isValidPhoneNumber(newOrg.phone_number) ? "success" : "error"} />
          <GoogleInput
            onPlaceSelected={onPlaceSelected}
            value={newOrg.address?.address}
            onInput={handlePlaceInput}
          />
          {formError && <div style={{ color: 'red' }}>{formError}</div>}
        </DialogContent>
        <DialogActions sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}>
          <CloseButton onClick={() => { setOpen(false); onClearForm() }} text={'Cancel'} />
          <PrimaryButton
            isDisabled={!validateFormCreate()}
            text="Add organisation"
          ></PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OrganisationForm;
