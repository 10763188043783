
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Autocomplete,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { decideTagType } from '..'
import { ReactComponent as FavAddIcon } from '../../../../assets/fav-add.svg'
import { ReactComponent as RatingIcon } from '../../../../assets/rating.svg'
import { get, put } from '../../../../utils/api'
import {
  CANCELLED,
  OPEN,
  SHIFT_STATES,
  assignmentGroupType,
  bonusList
} from '../../../../utils/constants'
import {
  convertDateWithDayAndYear,
  isFavoriteAssigned,
  isIndividualAssigned,
  upperCaseFirstLetter
} from '../../../../utils/helper_functions'
import theme from '../../../../utils/theme'
import { compareTimePickerValues } from '../../../../utils/time'
import AddBlacklistForm from '../../../forms/AddBlacklistForm'
import ConfirmBlacklistForm from '../../../forms/ConfirmBlacklistForm'
import RemoveBlacklistForm from '../../../forms/RemoveBlacklistForm'
import { CustomAvatar } from '../../../Layout'
import {
  CloseButton,
  DropDownButton,
  PrimaryButton
} from '../../../reusable/Buttons'
import { useFetchAssignOption } from '../../../reusable/hooks/useFetchAssignOption'
import { useModal } from "../../../reusable/modals/modal_provider"
import {
  Column,
  PaddingBox,
  Row,
  Space,
  SpacedRow
} from '../../../reusable/Scaffolds'
import {
  CommonTextField,
  TimeRangePicker
} from '../../../reusable/TextField'
import {
  M3BodyLarge,
  M3BodySmall,
  M3LabelMedium
} from '../../../reusable/TextStyles'
import EducatorDocumentModal from './educator_document_modal'
import RatingModalContent from './RatingModal'

const ShiftDetailTab = ({
  shiftData,
  educatorInfo,
  statusShift,
  setAlert,
  reload,
  currentUser,
  orgId,
  backButtonHandler
}) => {
  const navigate = useNavigate()
  const [valueEducatorPool, setEducatorPool] = React.useState(
    shiftData.public ? 'QuickCare only' : 'Private only'
  )
  const [shiftDataState, setShiftDataState] = React.useState(shiftData)
  const [allEducators, setAllEducators] = useState([]);

  // Modal blacklist
  const [reasonAddBlacklist, setReasonAddBlacklist] = React.useState('')
  const [openAddBlacklistModal, setOpenAddBlacklistModal] = React.useState(false)
  const [openRemoveBlacklistModal, setOpenRemoveBlacklistModal] = React.useState(false)
  const [openConfirmBlacklistModal, setOpenConfirmBlacklistModal] = React.useState(false)

  // Educator Document Modal
  const [openEducatorDocumentModal, setOpenEducatorDocumentModal] = React.useState(false);

  const { openModal, closeModal } = useModal();

  //favorite educator
  const [isFavoriteEducator, setIsFavoriteEducator] = useState(educatorInfo?.is_favorite);
  const [favoriteDisable, setFavoriteDisable] = React.useState(false)

  const {assignOptions, fetchAssignOptions} = useFetchAssignOption();

  const assignmentTypeRef = useRef(shiftData?.assigment_types)

  const individualOrFavoriteAssinged = useMemo(() => {
    return isIndividualAssigned(shiftDataState.assigment_types) || isFavoriteAssigned(shiftDataState.assigment_types);
  }, [shiftDataState.assigment_types]);

  const isFavoriteRejected = useMemo(() => {
    return shiftDataState.previous_assigment_type === assignmentGroupType.favorite && shiftDataState.assigment_types === assignmentGroupType.invidualRejected
  }, [shiftDataState.assigment_types, shiftDataState.previous_assigment_type]);

  const handleOpenEducatorDocumentModal = () => {
    setOpenEducatorDocumentModal(true);
  };

  const handleCloseEducatorDocumentModal = () => {
    setOpenEducatorDocumentModal(false);
  };

  const handleAddBlacklist = (reason) => {
    setReasonAddBlacklist(reason)
    setOpenAddBlacklistModal(false)
    setOpenConfirmBlacklistModal(true)
  }

  // Menu card filled 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  function validateFormCreate() {
    const validateEndTimeSmallerThanStartTime =
      compareTimePickerValues(shiftDataState.shift_start, shiftDataState.shift_end) === -1
    const validateUserId = individualOrFavoriteAssinged
      ? !!shiftDataState.educator_id
      : true
    return (
      shiftDataState.shift_start !== '' &&
      shiftDataState.shift_end !== '' &&
      validateEndTimeSmallerThanStartTime &&
      validateUserId &&
      shiftDataState.assigment_types
    )
  }

  const changeEducatorPool = useCallback((event) => {
    const newAssignmentType = isIndividualAssigned(event.target.value)
      ? assignmentGroupType.invidualAssigned
      : event.target.value;

    let educatorId = individualOrFavoriteAssinged ? shiftDataState.educator_id : null;
    if (!allEducators.find(e => e.id === educatorId)) {
      educatorId = null;
    }

    setShiftDataState(prevState => ({
      ...prevState,
      assigment_types: newAssignmentType,
      educator_id: educatorId,
    }));
    setEducatorPool(event.target.value);
  }, [allEducators, individualOrFavoriteAssinged, shiftDataState.educator_id])

  useEffect(() => {
    let educator = allEducators.find((e) => e.id === shiftDataState?.educator_id);
    setShiftDataState(prevState => ({
      ...prevState,
      educator: educator,
    }))
  }, [shiftDataState?.educator_id, allEducators])

  const constShiftNoticeTitle = (shift) => {
    if (decideTagType(shift) === OPEN && shift.assigment_types === assignmentGroupType.invidualAssigned) {
      return 'Pending acceptance by'
    }
    if (decideTagType(shift) === OPEN && shift.assigment_types === assignmentGroupType.invidualRejected) {
      return 'Declined by'
    }
    return 'Filled by'
  }

   const setEducatorId = (educatorId) => {
    setShiftDataState({
      ...shiftDataState,
      educator_id: educatorId
    })
  }

  const educatorId = useMemo(() => {
    return educatorInfo?.educator_id;
  }, [educatorInfo?.educator_id])

  const handleAddFavoriteEducator = useCallback(async (isFavorite) => {
    try {
      await put(`/manage/organisations/${orgId}/educators/${educatorId}/favorite`, { is_favorite: isFavorite });
      setIsFavoriteEducator(isFavorite);
      setAlert(isFavorite ? 'Educator added to favourites' : 'Educator removed from favourites', 'success')
    } catch (error) {
      setAlert('Add educator to favourites failed', 'error')
    }
  }, [orgId, setAlert, educatorId]);

  async function updateShiftData() {
    if (compareTime(shiftDataState.shift_start, shiftDataState.shift_end))
      return setAlert('Shift start time must be before shift end time', 'error')
    try {
      await put(`/manage/shifts`, {
        ...shiftDataState,
        assigment_types: shiftDataState.assigment_types,
        centre_id: shiftDataState.centre.id,
        qualification: shiftDataState.qualification.id,
        public: valueEducatorPool === 'QuickCare only' ? true : false,
        isChangedAssignee: shiftDataState.educator_id !== shiftData.educator_id || shiftDataState.assigment_types !== shiftData.assigment_types
      })
      reload()
      setAlert('Shift updated successfully')
    } catch (error) {
      reload()
      setAlert(error.message, 'error')
    }
  }

  async function handleRemoveQuickCareEducatorFromShift() {
    try {
      await put(`/admin/shifts/${shiftDataState.id}/remove_quickcare_educator`, {
        educator_name: shiftDataState.educator.first_name + ' ' + shiftDataState.educator.last_name,
        educator_id: shiftDataState.educator.id
      })
      setAlert('Educator removed from shift successfully')
      backButtonHandler()
    } catch (error) {
      setAlert(error.message, 'error')
    }
  }
  function compareTime(startTime, endTime) {
    const [startTimeHours, startTimeMin] = startTime.split(':')
    const [endTimeHours, endTimeMin] = endTime.split(':')
    if (parseInt(startTimeHours) > parseInt(endTimeHours)) {
      return true
    } else if (parseInt(startTimeHours) === parseInt(endTimeHours)) {
      if (parseInt(startTimeMin) > parseInt(endTimeMin)) {
        return true
      }
    } else {
      return false
    }
  }
  function updateBonus(e) {
    setShiftDataState({ ...shiftDataState, bonus: e.target.value })
  }
  function getContentNote(statusShift) {
    switch (statusShift) {
      case OPEN:
        return 'Because the shift has already been posted, some fields cannot be edited. Please cancel the shift and create a new one, if you wish to change those fields.'
      case CANCELLED:
        return ''
      default:
        return 'This shift has been accepted by an educator and cannot be edited, nor can the assigned educator can be removed from the shift. If changes need to be made, the shift will need to be cancelled and re-posted.'
    }
  }

  function getTitleNote(statusShift) {
    switch (statusShift) {
      case OPEN:
        return 'Some fields cannot be edited'
      case CANCELLED:
        return ''
      default:
        return 'Shift cannot be edited'
    }
  }

  function getShiftAssignmentTitle(assigmentType) {
    switch (assigmentType) {
      case assignmentGroupType.favorite:
        return 'Favourite educators'
      case assignmentGroupType.invidualAssigned:
        return 'Individual educator'
      case assignmentGroupType.org:
        return shiftDataState.organisation_name ?? ''
      case assignmentGroupType.quickcare:
      case assignmentGroupType.centre:
        return upperCaseFirstLetter(assigmentType) + ' educators'
      default:
        return ''
    }
  }
  function convertDateTimeToServerFormat(time) {
    return time.$H + ':' + time.$m + ':' + time.$s
  }
  function setTimeRange(newValue) {
    setShiftDataState({
      ...shiftDataState,
      shift_start: convertDateTimeToServerFormat(newValue[0]),
      shift_end: convertDateTimeToServerFormat(newValue[1])
    })
  }
  
  const fetchOrgEducators = useCallback(async () => {
    const qualification_list = !shiftData.qualification.id? shiftData.qualification.id: `${shiftData.qualification.id},${shiftData.qualification.id + 1}`
    const params = {start_date: shiftData.shift_date, end_date: shiftData.shift_date, qualification_list, shift_id: shiftData.id, include_private_educator: true, 
      is_favorite: isFavoriteRejected || assignmentTypeRef.current === assignmentGroupType.favorite}
    const res = await get(`/manage/organisations/${orgId}/educators`, null, params);
    setAllEducators(res);
  }, [isFavoriteRejected, orgId, shiftData.id, shiftData.qualification.id, shiftData.shift_date]);

  const fetchOrgFavoriteEducators = useCallback(async () => {
    const qualification_list = !shiftData.qualification.id? shiftData.qualification.id: `${shiftData.qualification.id},${shiftData.qualification.id + 1}`
    const params = {start_date: shiftData.shift_date, end_date: shiftData.shift_date, qualification_list, shift_id: shiftData.id, include_private_educator: true, 
      is_favorite: true}
    const res = await get(`/manage/organisations/${orgId}/educators`, null, params);
    if(res.length === 0) {
      setFavoriteDisable(true)
      setEducatorPool('')
    } else {
      setFavoriteDisable(false)
    }
  }, [orgId, shiftData.id, shiftData.qualification.id, shiftData.shift_date]);

  const getAssignShift = () => {
    if(isFavoriteRejected) {
      return assignmentGroupType.favorite
    }
    return isIndividualAssigned(shiftDataState.assigment_types)
                ? assignmentGroupType.invidualAssigned
                : shiftDataState.assigment_types
  }


  useEffect(() => {
    setShiftDataState({
      ...shiftData,
      bonus: shiftData.bonus ? shiftData.bonus.toString() : '0'
    })

    fetchAssignOptions(orgId, shiftData.centre.id)
  }, [shiftData, fetchAssignOptions, orgId, currentUser.id])

  useEffect(() => {
    assignmentTypeRef.current = shiftDataState?.assigment_types
    fetchOrgEducators()
  }, [fetchOrgEducators, shiftDataState?.assigment_types])

  useEffect(() => {
    fetchOrgEducators()
    fetchOrgFavoriteEducators()
  }, [fetchOrgEducators, fetchOrgFavoriteEducators])

  const shouldShowEducatorInfo =
  ((educatorInfo != null && (isIndividualAssigned(shiftData.assigment_types) || isFavoriteAssigned(shiftData.assigment_types))) ||
    (educatorInfo != null && shiftData.state !== SHIFT_STATES.AVAILABLE)) && !(
     shiftData.state === SHIFT_STATES.EXPIRED &&
     (isIndividualAssigned(shiftData.assigment_types) || isFavoriteAssigned(shiftData.assigment_types)))
 
     return (
    <div>
      <PaddingBox />
      {shouldShowEducatorInfo && (
        <Box sx={{ m: 1, backgroundColor: theme.colors.colorEBF9FF }}>
          <ListItem
            sx={{
              backgroundColor: theme.palette.blueJeans.main,
              color:theme.palette.blueJeans.onBlueJeans
            }}
          >
            <ListItemAvatar>
              <CustomAvatar
                src={
                  educatorInfo.avatar_url !== null
                    ? educatorInfo.avatar_url
                    : null
                }
              />
            </ListItemAvatar>
            <SpacedRow>
              <ListItemText
                primary={
                  <M3LabelMedium sx={{ color: theme.palette.text.main }}>
                    {constShiftNoticeTitle(shiftData)}
                  </M3LabelMedium>
                }
                secondary={
                  <React.Fragment>
                    <Space gap={3 }>
                      <Space direction='vertical' gap={0}  style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <M3BodyLarge sx={{ color: theme.palette.text.main }}>
                          {educatorInfo.first_name ?? ''}
                          {educatorInfo.prefered_name
                            ? ' (' + educatorInfo.prefered_name + ') '
                            : ' '}
                          {educatorInfo.last_name ?? ''}
                        </M3BodyLarge>
                        <M3BodySmall sx={{ color: theme.palette.text.main }}>
                          {educatorInfo.qualification_level}
                        </M3BodySmall>
                      </Space>
                      <Space direction='vertical' gap={0}>
                        { isFavoriteEducator && 
                          <Space gap={'8px'} style={{ alignItems: 'center' }}>
                            <FavAddIcon sx={{ fontSize: 24 }}/> 
                            <M3BodyLarge sx={{ color: theme.palette.text.main }}>
                              {`Your favourite`}
                            </M3BodyLarge>
                          </Space>
                        }
                        <Space gap={'8px'} style={{ alignItems: 'center', marginBottom: isFavoriteEducator? '0px' : '15px' }}>
                          <RatingIcon sx={{ fontSize: 24 }} />
                          <M3BodyLarge sx={{ color: theme.palette.text.main }}>
                            {`Rating ${educatorInfo?.rating || '--'} %`}
                          </M3BodyLarge>
                        </Space>
                      </Space>
                    </Space>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <div>
                  <MoreVertIcon
                    id="icon-menu"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleOpenMenu}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'icon-menu'
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuItem
                      disabled={!educatorInfo?.quickcare_educator}
                      onClick={(event) => {
                        event.preventDefault()
                        handleCloseMenu()
                        handleOpenEducatorDocumentModal();
                      }}
                    >
                      View Qualification Documents
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        event.preventDefault()
                        handleCloseMenu()
                        handleAddFavoriteEducator(!isFavoriteEducator);
                      }}
                    >
                      {!isFavoriteEducator ? 'Add educator to favourites' : 'Remove educator from favourites'}
                    </MenuItem>
                    <MenuItem
                      disabled={educatorInfo?.created_by !== currentUser?.id || educatorInfo?.updated_rating || shiftData?.state !== SHIFT_STATES.FINISHED}
                      onClick={(event) => {
                        event.preventDefault()
                        handleCloseMenu()
                        openModal(<RatingModalContent closeModal={closeModal} shiftId={shiftData.id} orgId={orgId} personId={educatorInfo.educator_id} reload={reload} />)
                      }}
                    >
                      Rate educator performance
                    </MenuItem>


                    {!currentUser.is_quickcare_admin ? (
                      shiftDataState.educator &&
                      shiftDataState.educator.is_blacklisted ? (
                        <MenuItem
                          onClick={(event) => {
                            event.preventDefault()
                            handleCloseMenu()
                            setOpenRemoveBlacklistModal(true)
                          }}
                        >
                          Remove Blacklist
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={(event) => {
                            event.preventDefault()
                            handleCloseMenu()
                            setOpenAddBlacklistModal(true)
                          }}
                        >
                          Block educator
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem
                        onClick={(event) => {
                          event.preventDefault()
                          handleCloseMenu()
                          handleRemoveQuickCareEducatorFromShift()
                        }}
                      >
                        Remove Educator from shift
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </ListItemSecondaryAction>
            </SpacedRow>
          </ListItem>
        </Box>
      )}
      <PaddingBox />
      <Column>
        <M3LabelMedium>{getTitleNote(statusShift)}</M3LabelMedium>
        <M3LabelMedium style={{ fontWeight: '400' }}>
          {getContentNote(statusShift)}
        </M3LabelMedium>
        <PaddingBox />
        <Row>
          <CommonTextField
            label={'Location'}
            value={shiftData.centre.name}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
        </Row>
        <PaddingBox />
        <Row>
          <CommonTextField
            label={'Required qualification'}
            value={shiftData.qualification?.qualification || 'Unqualified'}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
          {shiftData.role && (
            <>
              <PaddingBox />
              <CommonTextField
                label={'Role'}
                value={upperCaseFirstLetter(shiftData.role)}
                isDisable={true}
                icon={<ArrowDropDownIcon />}
              />
              <PaddingBox />
            </>
          )}
        </Row>
        <PaddingBox />
        <h4>Shift time</h4>
        <Row>
          <CommonTextField
            label={'Date'}
            value={convertDateWithDayAndYear(shiftData.shift_date)}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
          <PaddingBox />
          <TimeRangePicker
            isDisabled={statusShift !== OPEN}
            startTime={shiftDataState.shift_start}
            endTime={shiftDataState.shift_end}
            setData={setTimeRange}
          />
        </Row>
        <h4>Shift bonus</h4>
        <Row>
          <DropDownButton
            placeholder={'Shift bonus'}
            currentValue={shiftDataState.bonus ?? '0'}
            onChange={updateBonus}
            isDisabled={statusShift !== OPEN}
            options={bonusList.map((option) => (
              <MenuItem key={option} value={option} dense>
                {'$' + option}
              </MenuItem>
            ))}
          />
        </Row>
        <h4>Assign shift to</h4>
        <Row>
          <DropDownButton
            placeholder={'Choose who can see the shift'}
            currentValue={getAssignShift()}
            onChange={changeEducatorPool}
            isDisabled={assignOptions.length === 0 || statusShift !== OPEN}
            options={[...assignOptions.map((option) => (
              <MenuItem key={option} value={option} disabled={option === 'favorite' && favoriteDisable} dense>
                {getShiftAssignmentTitle(option)}
              </MenuItem>
            )),
            !assignOptions.includes(getAssignShift()) && (
              <MenuItem key="current" value={getAssignShift()} disabled dense>
                {getShiftAssignmentTitle(getAssignShift())}
              </MenuItem>
            )]}
          />
        </Row>
        {individualOrFavoriteAssinged && (
          <div>
            <h4>Choose the educator</h4>
            <Row>
              <Autocomplete
                disablePortal
                id="combo-box-educators"
                placeholder={'Search for / choose educator'}
                fullWidth={true}
                value={shiftDataState.educator}
                options={allEducators}
                disabled={statusShift !== OPEN}
                getOptionLabel={(option) => {
                  if (!option) {
                    return ''
                  }
                  return (
                    (option.first_name || '') + ' ' + (option.last_name || '')
                  )
                }}
                onChange={(event, educator) => {
                  setEducatorId(educator?.id)
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!option || !value) {
                    return false
                  }
                  return option !== null && option.id === value.id
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Row>
          </div>
        )}

        <h4>Description</h4>
        <Row>
          <CommonTextField
            isMultiline={true}
            rows={3}
            label={'Description'}
            onChange={(e) => {
              setShiftDataState({
                ...shiftDataState,
                description: e.target.value
              })
            }}
            value={shiftDataState.description}
            isDisable={statusShift !== OPEN}
          />
        </Row>
        <PaddingBox />
        {statusShift === OPEN ? (
          <SpacedRow>
            <CloseButton onClick={() => navigate('/shifts')} text={'Cancel'} />
            <PrimaryButton
              text="Save changes"
              onClick={updateShiftData}
              isDisabled={!validateFormCreate()}
            />
          </SpacedRow>
        ) : null}
      </Column>
      <ConfirmBlacklistForm
        open={openConfirmBlacklistModal}
        educatorInfo={shiftDataState.educator || {}}
        educatorQualification={educatorInfo && educatorInfo.qualification_level}
        setOpen={setOpenConfirmBlacklistModal}
        setCloseModalAdd={setOpenAddBlacklistModal}
        setAlert={setAlert}
        reason={reasonAddBlacklist}
        refreshDataShift={reload}
      />
      <AddBlacklistForm
        open={openAddBlacklistModal}
        educatorInfo={shiftDataState.educator || {}}
        setOpen={setOpenAddBlacklistModal}
        handleAddBlacklist={handleAddBlacklist}
      />
      {shiftDataState.black_listed && (
        <RemoveBlacklistForm
          open={openRemoveBlacklistModal}
          educatorInfo={shiftDataState.educator || {}}
          setOpen={setOpenRemoveBlacklistModal}
          setAlert={setAlert}
          refreshShift={reload}
          blacklistedInfo={shiftDataState.black_listed}
        />
      )}
      <EducatorDocumentModal
        open={openEducatorDocumentModal}
        onClose={handleCloseEducatorDocumentModal}
        educatorId={educatorInfo?.educator_id}
        orgId={orgId}
        isAdmin={currentUser.is_quickcare_admin}
      />
    </div>
  )
}

export default ShiftDetailTab