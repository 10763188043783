import React, {  } from "react";

import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import Video from "../../../../reusable/video";
import { formatTimestampToddmmyyyy } from "../../../../../utils/time";
import { CustomAvatar } from "../../../../Layout";
import {
  stringAvatar,
  extractSuburb,
  extractState,
} from "../../../../../utils/helper_functions";
import { AddButton } from "../../../../reusable/Buttons";

const InfoTab = ({
  member,
  openModal,
  profileDetailStyles,
  isModalOpen,
  closeModal,
  isLoading,
  getOpenTo,
  getAgeGroup,
  getNoticePeriod,
  getWWCCData,
  theme,
  inviteQuickcare,
  isCompleteStatus,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
        <CustomAvatar
          {...stringAvatar(member.first_name + " " + member.last_name)}
          src={member.avatar !== null ? member.avatar : null}
          sx={{
            width: 120,
            height: 120,
            mx: "auto",
            borderRadius: "50%",
          }}
        />
        <Button
          variant="contained"
          onClick={openModal}
          startIcon={<VideoCameraFrontIcon />}
          disabled={!member.video_url}
          sx={{ mt: 2, backgroundColor: "#7A4FFF" }}
        >
          Short video
        </Button>
        <Video
          videoUrl={member.video_url}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : (
          <Stack spacing={1}>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Rego State:
              </Typography>
              <Chip
                label={isCompleteStatus() ? "Complete" : "Verifying"}
                size="small"
                sx={{
                  backgroundColor: isCompleteStatus() ? "#93dcce" : "#ed6c02",
                }}
              />
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>Name:</Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.prefered_name}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>Suburb:</Typography>
              <Typography sx={profileDetailStyles.value}>{`${extractSuburb(
                member.address
              )} ${extractState(member.address)}`}</Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>Email:</Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.email}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Phone Number:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.phone_number}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Date of birth:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {formatTimestampToddmmyyyy(member.date_of_birth)}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Australian Citizen:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.is_aus_citizen ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>Open to:</Typography>
              <Typography sx={profileDetailStyles.value}>
                {getOpenTo(member.open_to)}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Preferred age groups:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {getAgeGroup(member.age_group)}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Notice period:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {getNoticePeriod(member.notice_period)}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                Distance willing to work:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.distance}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                WWCC Number:
              </Typography>
              <Typography sx={{ ...profileDetailStyles.value, width: "70%" }}>
                {getWWCCData(member.wwcc_data)}
              </Typography>
            </Box>
            <Box sx={profileDetailStyles.container}>
              <Typography sx={profileDetailStyles.label}>
                VEVO check:
              </Typography>
              <Typography sx={profileDetailStyles.value}>
                {member.vevo_check}
              </Typography>
            </Box>
          </Stack>
        )}
      </Grid>
      <Grid item xs={12} sm={2}>
        <AddButton
          onClick={inviteQuickcare}
          text="Invite to QC"
          disabled={member?.step !== "profile"}
          theme={theme}
        ></AddButton>
      </Grid>
      <Grid spacing={2} item xs={3} container>
        <Grid container item xs={12}>
          <Typography variant="h6">
            Photo ID
          </Typography>
        </Grid>
        <Grid container spacing={2} item xs={6}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Front</Typography>
            {member.identityLink && (
              <img
                src={member.identityLink}
                alt={"Front"}
                style={{
                  borderRadius: 8,
                  width: "200px",
                  height: "150px",
                  textAlign: "left",
                  border: "1px solid #ccc",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InfoTab;